import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { useDispatch } from "react-redux";
import store from "@/redux/store";

const MetaTags = dynamic(() => import("@/common/MetaTags"));
const MainSlider = dynamic(() => import("@/components/home/Sliders"));
const IntroSection = dynamic(() => import("@/components/home/IntroSection"));
const InfoIconSection = dynamic(() =>
  import("@/components/home/InfoIconSection")
);
const CoursesCard = dynamic(() => import("@/components/cards/CoursesCard"));
const SeparatorBtn = dynamic(() => import("@/components/common/SeparatorBtn"));

const TwoColumnGradientCard = dynamic(() =>
  import("@/components/common/TwoColumnGradientCard")
);
const TwoColumnIconBoxCardWithImage = dynamic(() =>
  import("@/components/common/TwoColumnIconBoxCardWithImage")
);
const SingleLargeIconBoxCardWithImage = dynamic(() =>
  import("@/components/common/SingleLargeIconBoxCardWithImage")
);

import { fetchHomePagesData } from "@/redux/actions/homePageActions";
import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";
import { HOMEPAGE_QUERY } from "@/graphql/homePageQuery";

import { getClassForRenderCourseCard } from "@/utils/functions";

const Home = (props) => {
  const { pageData, labelsConfigData, homePageData, coursesTypologiesList } =
    props;

  const [labelsList, setLabelsList] = useState();
  const [siteConfig, setSiteConfig] = useState();
  const [corusesCardData, setCorusesCardData] = useState([]);

  const dispatch = useDispatch();

  let cardClassList = getClassForRenderCourseCard();

  useEffect(() => {
    // Set labelsList local state while changed on redux store data
    dispatch(setLabelsConfigsData(labelsConfigData));
    setLabelsList(labelsConfigData?.labelsList);
    setSiteConfig(labelsConfigData?.siteConfig);
  }, [dispatch, labelsConfigData]);

  useEffect(() => {
    if (coursesTypologiesList?.coursesTypologies?.length > 0) {
      const slicedArray = coursesTypologiesList?.coursesTypologies?.slice(0, 3);
      setCorusesCardData(slicedArray);
    }
  }, [coursesTypologiesList]);

  return (
    <>
      <MetaTags
        metaTags={homePageData?._seoMetaTags}
        seoData={homePageData?.seo}
        noFollow={homePageData?.nofollow}
        noIndex={homePageData?.noindex}
      />

      <MainSlider sliderData={homePageData?.slider} labelsList={labelsList} />
      {homePageData?.icons?.length > 0 && (
        <InfoIconSection icons={homePageData?.icons} siteConfig={siteConfig} />
      )}

      <IntroSection
        title={coursesTypologiesList?.subtitle}
        abstract={coursesTypologiesList?.abstract}
        siteConfig={siteConfig}
      />

      {corusesCardData?.length > 0 && (
        <>
          <section>
            <div className="container">
              <div className="grid md:grid-cols-2 lg:grid-cols-6 gap-10 pt-16">
                {corusesCardData?.map((item, index) => {
                  return (
                    <CoursesCard
                      key={index}
                      icon={item?.icon?.webp || item?.icon?.url}
                      title={item?.subtitle?.replace(
                        item?.subtitle?.split(" ").pop(),
                        ""
                      )}
                      type={item?.subtitle?.split(" ").pop()}
                      date={item?.age}
                      text={item?.abstract}
                      bgColor={item?.boxBackgroundColor?.hex}
                      textColor={item?.buttonDefaultColor?.hex}
                      defaultColor={item?.buttonDefaultColor?.hex}
                      hoverColor={item?.buttonHoverColor?.hex}
                      pressedColor={item?.buttonPressedColor?.hex}
                      showDescText={true}
                      asLink={`/offerta/${
                        coursesTypologiesList?.slug
                      }/${item?.title?.toLowerCase()?.replace(/ +/g, "-")}`}
                      url={`/offerta/[slug]/[typology]`}
                      labelsList={labelsList}
                      className={cardClassList[corusesCardData?.length][index]}
                    />
                  );
                })}
              </div>
            </div>
          </section>
          <div className="container">
            <SeparatorBtn
              text={labelsList?.allCourses}
              slug={`/offerta/${coursesTypologiesList?.slug}`}
              siteConfig={siteConfig}
            />
          </div>
        </>
      )}

      <section>
        <TwoColumnGradientCard
          standardPage={pageData?.homePageData?.standardPage}
          externalLinkPage={pageData?.homePageData?.externalLinkPage}
          labelsList={labelsList}
        />
        <div className="container">
          <SingleLargeIconBoxCardWithImage
            certificationsList={pageData?.homePageData?.certificationsList}
            labelsList={labelsList}
          />
          <TwoColumnIconBoxCardWithImage
            pageBoxesList={homePageData?.pageBoxesList}
            labelsList={labelsList}
          />
        </div>
      </section>
    </>
  );
};

export default Home;

export async function getStaticProps({ preview = false, locale = "en" }) {
  let requestParams = HOMEPAGE_QUERY?.replace(/\lang/g, locale);

  const homeResponse = await store.dispatch(
    fetchHomePagesData(requestParams, preview)
  );

  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale })
  );

  return {
    props: {
      preview,
      homePageData: homeResponse?.home,
      coursesTypologiesList: homeResponse?.coursesTypologiesList,
      pageData: { homePageData: homeResponse },
      labelsConfigData: labelsConfigData || {},
    },
  };
}
